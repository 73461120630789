
//關鍵字設定
export const metaKeywords = {
    group_A: '雋揚國際, 雋揚建築, ASCENT DEVELOPMENT, 台灣建商, 台灣房地產, 不動產, 營建業, 建設, 國揚建設, 國揚集團, 國揚相關企業, 高雄全家海神, 中和羊毛, 中和羊毛股份有限公司',
}

export const filesPath = {
    ascentURL: 'https://www.ascentglobal.com.tw/',
    reportPath: 'download/report/',
    recordPath: 'download/record/',
    noticePath: 'download/notice/',
    notePath: 'download/note/',
    procedurePath: 'download/procedure/',
    casePhotoPath: 'photo/case/',

}

export const imgPath = {
    imageSrc: `./assets/images/`,
    mainVisual_desktop: './assets/images/mainVisual/desktop/',
    mainVisual_tablet: './assets/images/mainVisual/desktop/',
    mainVisual_mobile: './assets/images/mainVisual/desktop/',
    iconPath: `./assets/images/page-nav-icon/`,
}

export const errorPath = {
    pageTitle: '找不到頁面',
    pageType: 'error',
}

export const soonPath = {
    pageTitle: '頁面施工中',
    pageType: 'soon',
}

export const AppPathSetting = {

    //首頁
    home: {
        order: 0,
        path: '',
        name_CH: '首頁',
        name_EN: '',
        pageTitle: '雋揚國際股份有限公司 ASCENT DEVELOPMENT',
        pageType: 'home',
        meta_descp: '本公司隸屬-國揚集團。國揚集團，自1972年成創立以來，以不動產建築營造、百貨商場、觀光飯店、複合式巨蛋興建等建築發跡,從無到有、從有到多、從多到精緻，奠定品牌精神。本公司原為羊毛產業上游廠商(中和羊毛工業股份有限公司)，成立50年來均以產品品質優良，股票上市20餘年， 營運狀況穩健著稱 ; 於2019年加入國揚集團，2022年6月份調整營運方向轉型為土地開發建設產業，持續為股東創造更大的收益，同時提供員工更多元的工作發展。',
        meta_keyword: metaKeywords.group_A,
        breadcrumbClass: 0,
    },

    about: {
        order: 1,
        path: 'about',
        name_CH: '關於雋揚',
        name_EN: 'About',
        pageType: 'landingPage',
        meta_descp: '雋典恆揚，與時建築。雋，「永恆光芒」，象徵溫暖的能量原點與時間力量。揚，「乘光而揚」，在時光的長河之上展翼遨翔。雋揚建築，與世界共鳴共好之路，共築永恆建築，不斷向上進化。',
        meta_keyword: metaKeywords.group_A,
        breadcrumbClass: 1,

        children: {
            intro: {
                order: 1,
                path: null,
                fragment: 'intro',
                name_CH: '公司理念',
                name_EN: 'intro',
                pageType: 'page',
                icon: 'ascent_about_01',
            },
            philosophy: {
                order: null,
                path: null,
                fragment: 'philosophy',
                name_CH: '五恆為一',
                name_EN: 'Philosophy',
                pageType: 'page',
                icon: '',
            },
            history: {
                order: 2,
                path: null,
                fragment: 'history',
                name_CH: '公司沿革',
                name_EN: 'History',
                pageType: 'page',
                icon: 'ascent_about_02',
            },
            team: {
                order: 3,
                path: null,
                fragment: 'team',
                name_CH: '經營團隊',
                name_EN: 'Team',
                pageType: 'page',
                icon: 'ascent_about_03',
            },
            organization: {
                order: 4,
                path: null,
                fragment: 'organization',
                name_CH: '組織架構',
                name_EN: 'Organization',
                pageType: 'page',
                icon: 'ascent_about_04',
            },
            affiliate: {
                order: 5,
                path: null,
                fragment: 'affiliate',
                name_CH: '關係企業',
                name_EN: 'Affiliate',
                pageType: 'page',
                icon: 'ascent_about_05',
            },
        }
    },

    showcase: {
        order: 2,
        path: 'showcase',
        name_CH: '作品鑑賞',
        name_EN: 'Showcase',
        pageType: 'landingPage',
        meta_descp: '雋揚國際, 雋揚建築, 建案, 廠辦, 商辦, 住宅, 危老重建, 都更, 國揚集團子公司',
        meta_keyword: metaKeywords.group_A,
        breadcrumbClass: 1,

        children: {
            classic: {
                order: 0,
                path: 'classic',
                name_CH: '經典個案',
                name_EN: 'Classic',
                pageType: 'page',
                icon: 'ascent_use_icon_showcase_classic',
                kv: 'kv_showcase',
            },
            latest: {
                order: 1,
                path: 'latest',
                name_CH: '熱銷鑑賞',
                name_EN: 'Latest',
                pageType: 'page',
                icon: 'ascent_use_icon_showcase_latest',
                kv: 'kv_showcase',
                children: {
                    detail: {
                        order: 0,
                        path: 'detail/:id',
                        name_CH: '詳細資訊',
                        name_EN: '',
                        pageType: 'detail',
                        icon: '',
                    },
                }
            },
            preparation: {
                order: 2,
                path: 'preparation',
                name_CH: '即將推出',
                name_EN: 'Preparation',
                pageType: 'page',
                icon: 'ascent_use_icon_showcase_preparation',
                kv: 'kv_showcase',
            },

        }
    },

    investor: {
        order: 3,
        path: 'investor',
        name_CH: '投資人專區',
        name_EN: 'Investor',
        pageType: 'fullPage',
        meta_descp: '雋揚建築, 投資人專區, 投資人資訊, 財務資訊, 股東服務, 股東會資訊',
        meta_keyword: metaKeywords.group_A,
        breadcrumbClass: 1,

        children: {

            IR: {
                order: 0,
                path: 'IR',
                name_CH: '投資人資訊',
                name_EN: 'IR',
                pageType: 'page',
                icon: 'ascent_use_icon_investor_IR',
                kv: 'kv_investor_IR',
                breadcrumbClass: 2,
            },

            finance: {
                order: 1,
                path: 'finance',
                name_CH: '財務資訊',
                name_EN: 'Finance',
                pageType: 'page',
                icon: 'ascent_use_icon_investor_finance',
                kv: 'kv_investor_finance',
                breadcrumbClass: 2,
            },

            service: {
                order: 2,
                path: 'service',
                name_CH: '股東服務',
                name_EN: 'Service',
                pageType: 'page',
                icon: 'ascent_use_icon_investor_service',
                kv: 'kv_investor_service',
                breadcrumbClass: 2,
            },

            major: {
                order: 3,
                path: 'major',
                name_CH: '主要股東',
                name_EN: 'Major',
                pageType: 'page',
                icon: 'ascent_use_icon_investor_major',
                kv: 'kv_investor_major',
                breadcrumbClass: 2,
            },

            shareholders: {
                order: 4,
                path: 'shareholders',
                name_CH: '股東會資訊',
                name_EN: 'Shareholders',
                pageType: 'page',
                icon: 'ascent_use_icon_investor_CInfo',
                kv: 'kv_investor_shareholders',
                breadcrumbClass: 2,
            },

        }

    },

    governance: {
        order: 4,
        path: 'governance',
        name_CH: '公司治理',
        name_EN: 'Governance',
        pageType: 'page',
        meta_descp: '雋揚建築, 公司治理, 利害關係人, 永續發展',
        meta_keyword: metaKeywords.group_A,
        breadcrumbClass: 1,

        children: {

            policies: {
                order: 0,
                path: 'policies',
                name_CH: '公司治理',
                name_EN: 'Policies',
                pageType: 'page',
                icon: 'ascent_use_icon_gover_policies',
                kv: 'kv_governance_policies',
                breadcrumb: 2,
            },

            stakeholder: {
                order: 1,
                path: 'stakeholder',
                name_CH: '利害關係人',
                name_EN: 'Stakeholder',
                pageType: 'page',
                icon: 'ascent_use_icon_gover_stakeholder',
                kv: 'kv_governance_stakeholder',
                breadcrumb: 2,
            },

            csr: {
                order: 2,
                path: 'csr',
                name_CH: '永續發展',
                name_EN: 'CSR',
                pageType: 'page',
                icon: 'ascent_use_icon_gover_envir',
                kv: 'kv_governance_csr',
                breadcrumbClass: 2,
            }

        }

    },

    urban: {
        order: 5,
        path: 'urban',
        name_CH: '都更專區',
        name_EN: 'Urban Renewal',
        pageType: 'page',
        meta_descp: '雋揚國際, 雋揚建築, 都更專區, 都更資訊',
        meta_keyword: metaKeywords.group_A,
        kv: 'kv_urban',
        breadcrumbClass: 1,

        children: {
            detail: {
                order: 0,
                path: 'detail/:id',
                name_CH: '詳細資訊',
                name_EN: '',
                pageType: 'detail',
                icon: '',
            },
        }
    },

    contact: {
        order: 6,
        path: 'contact',
        name_CH: '聯絡我們',
        name_EN: 'Contact us',
        pageType: 'page',
        meta_descp: '雋揚國際, 雋揚建築, 聯繫資訊',
        meta_keyword: metaKeywords.group_A,
        kv: 'kv_contact',
        breadcrumbClass: 1,

        children: {
            info: {
                order: 0,
                path: 'info',
                name_CH: '聯絡資訊',
                name_EN: 'Information',
                pageType: 'page',
                icon: 'ascent_use_icon_contact_info',
                breadcrumbClass: 2,
            },
            service: {
                order: 1,
                path: 'service',
                name_CH: '服務信箱',
                name_EN: 'Service',
                pageType: 'page',
                icon: 'ascent_use_icon_contact_service',
                breadcrumbClass: 2,
            },
        }
    },

    member: {
        order: 7,
        path: 'member',
        name_CH: '會員中心',
        name_EN: 'Member Center',
        pageType: 'page',
        meta_descp: '雋揚國際, 雋揚建築, 會員中心',
        meta_keyword: metaKeywords.group_A,
        kv: 'kv_member',
        breadcrumbClass: 1,

        children: {
            payment: {
                order: 0,
                path: 'payment',
                name_CH: '繳款查詢',
                name_EN: 'Payment',
                pageType: 'page',
                icon: 'ascent_use_icon_member_payment',
                kv: 'kv_member_case',
                breadcrumbClass: 2,
            },
            feedback: {
                order: 1,
                path: 'feedback',
                name_CH: '意見反應',
                name_EN: 'Feedback',
                pageType: 'page',
                icon: 'ascent_use_icon_member_feedback',
                kv: 'kv_member_case',
                breadcrumbClass: 2,
            },

        }
    },

    privacy: {
        order: 8,
        path: 'privacy-policy',
        name_CH: '隱私權政策',
        name_EN: 'Privacy Policy',
        pageType: 'page',
        meta_descp: '雋揚國際, 雋揚建築, 隱私權政策',
        meta_keyword: metaKeywords.group_A,
        kv: 'kv_privacy',
        breadcrumbClass: 1,
    },

    login: {
        order: 9,
        path: 'login',
        name_CH: '會員登入',
        name_EN: 'Login',
        pageType: 'login',
        meta_descp: '雋揚國際, 雋揚建築, 會員登入',
        meta_keyword: metaKeywords.group_A,
        kv: 'kv_login',
    },

    privacy_goyoung: {
        order: 10,
        path: 'privacy-policy/goyoung',
        name_CH: 'Goyoung App - 隱私權政策',
        name_EN: 'Goyoung App - Privacy Policy',
        pageType: 'page',
        meta_descp: 'Goyoung App - 隱私權政策',
        meta_keyword: '',
        kv: 'kv_privacy',
    }


}


export const AppPathParam = {
    caseId: ':caseId',
}

